<script>

import FormSubmitComponent from './_partials/FormSubmitComponent.vue';

export default {
    components: {
        FormSubmitComponent
    },
    props : {
        formConfig: Object,
        formData: Object,
        flashErrorData: Object,
        isSuperAdmin: Boolean,
        adminRoles: Array,
        model: Object,
        modelName: String,
        oldData: Object,
        route: String,
        redirect: {
            type: String,
            default: ''
        },
        tableName: String,
        componentFile: {
            type: String,
            default: () => null
        },
        options: {
            type: Array,
            default: () => []
        },
        additionalProps: Object,
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false,
            formType: 'edit',
        }
    },
    mounted: function() {
        if (this.modelName) {
            this.loadComponent()
        }
    },
    computed: {
        componentLoader: function() {
            // Import child component by modelName
            // return () => import('./'+this.modelName+'/Form'+this.modelName+'Component');
            return () => import(this.formDirectory+this.modelName+'/Form'+this.modelName+'Component');
        },
        formAction: function() {
            return this.route + '/' + this.model.id;
        },
        formDirectory: function() {
            if (this.formConfig.formDirectory) {
              return './'+this.formConfig.formDirectory+'/';
            } else {
              return './';
            }
        },
        formId: function() {
            return 'form-' + this.modelName.toLowerCase() + '-edit';
        },
        // isSuperAdminBoolean: function() {
        //   return this.isSuperAdmin == 1 ? true: false;
        // }
    },
    directives: {
    },
    methods: {
        loadComponent: function() {
            this.componentLoader().then(comp => {
                // console.log('comp', comp);
            });
        }
    }
}
</script>

<template>
    <component
      :is="componentLoader"
      :model="model"
      :additionalProps="additionalProps"
      :modelName="modelName"
      :tableName="tableName"
      :isSuperAdmin="isSuperAdmin"
      :adminRoles="adminRoles"
      :redirect="redirect"
      :route="route"
      :formConfig="formConfig"
      :formData="formData"
      :formType="formType"
      :oldData="oldData"
      :flashErrorData="flashErrorData">
    </component>
</template>
