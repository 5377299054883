<script>

export default {
    components: {},
    props: {
        componentPath: String,
        componentProps: Object
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false
        }
    },
    computed: {
        componentLoader: function() {
            // Import components in componentList
// console.log(this.componentPath);
            return () => import('./'+this.componentPath);
        }
    },
    methods: {
        loadComponent: function() {
            this.componentLoader().then(comp => {
                console.log('component loaded: ', comp);
            });
        }
    },
    mounted: function() {
        // console.log('--- LoaderComponent ---');
        // console.log(this.componentPath);

        if (this.componentPath) {
            this.loadComponent()
        };

        // this.$on('parent', value => {
        //     console.log('parent');
        // });
    },
}
</script>

<template>
    <!-- <div>
      here2
    </div> -->
        <component :is="componentLoader" v-bind="componentProps"></component>
</template>
