<script>

import { mapState } from 'vuex'

export default {
    name: 'FlashErrorComponent',
    props : {
      alertClass: String,
      errors: Object,
      indexParent: {
        type: Object,
        default: () => {
            return {}
        }
      },
      indexPrefix: {
        type: String,
        default: null,
      },
      message: String,
      status: String,
    },
    data: function() {
        return {
          refresh: false,
        }
    },
    mounted: function() {},
    computed: {
      ...mapState('flash', {
        FlashData: state => state.data,
        FlashStatus: state => state.status,
      }),
      alertIcon: function() {
        switch(this.alertClass) {
          case 'alert-danger':
            return 'fa-exclamation-circle';
            break;
          case 'alert-success':
            return 'fa-check-circle';
            break;
          default:
            return 'fa-exclamation-circle';
        }
      },
      indexCurrent: function() {
          if (this.indexPrefix) {
            this.indexParent[this.indexPrefix] = {};
            return this.indexParent;

          } else {
            return this.indexParent;

          }
      }
    },
    watch: {},
    directives: {},
    methods: {
      clearError: function(index) {

        this.$store.dispatch('flash/deleteErrorItem', {
          item: index,
          reactive: true,
        });
        // this.$store.dispatch('flash/resetResponse', {
        //   data: {},
        //   status: null,
        // });
      },
      clearMessage: function() {
        this.$store.dispatch('flash/resetResponse');
      }
    },
}
</script>

<template>
  <transition name="fade" mode="out-in">
      <div v-if="errors" :key="refresh">
        <div v-for="(error, index) in errors">
          <div v-if="error.hasOwnProperty('0')" class="alert alert-dismissable alert-danger" dusk="flash-component-errors">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" v-on:click.stop="clearError(index)">
                  <span aria-hidden="true">&times;</span>
              </button>
              <ul>
                  <li>
                      <i class="fas fa-exclamation-circle"></i> {{ error[0] }}
                  </li>
              </ul>
          </div>
          <div v-else>
            <flash-error-component :alertClass="alertClass" :errors="error" :errorStatus="status" :message="message"
              :indexParent="indexCurrent" :indexPrefix="index"></flash-error-component>
          </div>
        </div>
      </div>
      <div v-else-if="status === 'success'" :key="refresh" class="alert alert-dismissable" :class="alertClass"
        dusk="flash-component-success">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="clearMessage()">
            <span aria-hidden="true">&times;</span>
        </button>
        <ul>
            <li>
                <i class="fas" :class="alertIcon"></i> {{ message }}
            </li>
        </ul>
      </div>
      <div v-else-if="message" :key="refresh" class="alert alert-dismissable alert-danger" dusk="flash-component-message">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="clearMessage()">
            <span aria-hidden="true">&times;</span>
        </button>
        <ul>
            <li>
                <i class="fas fa-exclamation-circle"></i> {{ message }}
            </li>
        </ul>
      </div>
  </transition>

</template>
