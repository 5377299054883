<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css'

export default {
    name: 'CostCalculatorComponent',
    components: {
        VueSlider,
    },
    props: {},
    data: function() {
        return {
            autonome: {
                hours: 2,
                rate: 13,
            },
            rate: {
                // marks: ['13.00',14,15,16,17,18,19,20,21,22,23,24,25],
                // marks: this.rateMarks,
                min: 13,
                max: 25,
                interval: 0.01,
                value: 16.77,
            },
            hours: {
                min: 1,
                max: 3,
                interval: 1,
                value: 2,
            },
            users: {
                min: 0,
                max: 1000,
                interval: 100,
                data: [1, 2, 5, 10, 50, 100, 250, 500, 1000, 2000],
                marks: [1, 2, 5, 10, 50, 100, 250, 500, 1000, 2000],
                value: 100,
            }
        }
    },
    computed: {
        rate2dp: {
            get: function() {
                return this.rate.value = parseFloat(this.rate.value).toFixed(2);
            },
            set: function(newValue) {
                this.rate.value = newValue;
            }
        },
        rateMarks: function() {
            let marks = [];
            for (var i = this.autonome.rate; i <= 25; i++) {
                // console.log(i);
                // marks.push(parseFloat(i).toFixed(2));
                marks.push(parseFloat(i));
            }
            return marks;
        },
        saving1yr: function() {
            // return Math.round((this.rate.value - this.autonome.rate) * this.autonome.hours * this.users.value * 52);
            return Math.round((this.rate.value - this.autonome.rate) * this.hours.value * this.users.value * 52);
        },
        saving1yrDisplay: function() {
            return this.saving1yr.toLocaleString();
        },
        saving3yr: function() {
            return this.saving1yr * 3 + this.rate.value * this.hours.value * this.users.value * 52;
        },
        saving3yrDisplay: function() {
            return this.saving3yr.toLocaleString();
        },
        saving10yr: function() {
            return this.saving1yr * 10 + this.rate.value * this.hours.value * this.users.value * 52;
        },
        saving10yrDisplay: function() {
            return this.saving10yr.toLocaleString();
        },
    },
    methods: {},
    mounted: function() {},
    watch: {},
    validations: {}
}
</script>

<template>
<div>
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-12 col-lg-9 col-xl-8">
            <div class="card">
                <div class="card-header">
                    <h4><strong>AutonoMe Savings Calculator</strong></h4>
                </div>
                <div class="card-body">
                    <p>This tool helps Local Authorities understand and predict the cost savings of your Learning Disability service users with AutonoMe.</p>
                    <p>1 hour of AutonoMe support is £13.</p>
                    <p>On average AutonoMe support most service users for 2 hours per week.</p>
                    <form class="form">
                        <div class="form-group row">
                            <label class="col-9 col-xl-7 col-form-label" for="rate">
                                <strong>Please enter your average hourly cost of supporting a service user (&pound;): </strong>
                            </label>
                            <div class="col-3 d-flex">
                                <input id="rate" class="form-control-sm align-self-center no-validation" v-model="rate.value" type="number"
                                 :min="rate.min" :max="rate.max" :step="rate.interval">
                            </div>
                        </div>
                    </form>
                    <div class="row justify-content-center mb-5">
                        <div class="col-12 col-sm-11">
                            <vue-slider v-model="rate2dp" :min="rate.min" :max="rate.max" :interval="rate.interval" :marks="rateMarks" :tooltip="'none'"></vue-slider>
                        </div>
                    </div>

                    <form class="form">
                        <div class="form-group row">
                            <label class="col-9 col-xl-7 col-form-label" for="users">
                                <strong>Please select the number of service users who could benefit from AutonoMe support: </strong>
                            </label>
                            <div class="col-3 d-flex">
                                <input id="users" class="form-control-sm align-self-center no-validation" v-model="users.value" type="number"
                                 :min="users.min" :max="users.max" />
                            </div>
                        </div>
                    </form>
                    <div class="row justify-content-center mb-5">
                        <div class="col-12 col-sm-11">
                            <vue-slider v-model="users.value" :min="users.min" :max="users.max" :marks="true" :interval="users.interval" :tooltip="'none'"></vue-slider>
                        </div>
                    </div>

                    <form class="form">
                        <div class="form-group row">
                            <label class="col-9 col-xl-7 col-form-label" for="hours">
                                <strong>Please select the number of weekly support hours for each service user: </strong>
                            </label>
                            <div class="col-3 d-flex">
                                <input id="hours" class="form-control-sm align-self-center no-validation" v-model="hours.value" type="number"
                                 :min="hours.min" :max="hours.max" step="1" />
                            </div>
                        </div>
                    </form>
                    <div class="row justify-content-center mb-5">
                        <div class="col-12 col-sm-11">
                            <vue-slider v-model="hours.value" :min="hours.min" :max="hours.max" :marks="true" :interval="hours.interval" :tooltip="'none'"></vue-slider>
                        </div>
                    </div>


                    <div class="row mb-4 pt-3">
                        <div class="col-4">
                            <div class="row justify-content-center">
                                <div class="card">
                                    <div class="card-header px-3 px-md-4">
                                        <h5 class="mb-0 d-none d-md-block"><strong>Year 1 savings</strong></h5>
                                        <h6 class="mb-0 d-block d-md-none"><strong>Year 1 savings</strong></h6>
                                    </div>
                                    <div class="card-body text-center px-1">
                                        <h5 class="text-primary"><strong>&pound;{{ saving1yrDisplay }}</strong></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row justify-content-center">
                                <div class="card">
                                    <div class="card-header px-3 px-md-4">
                                        <h5 class="mb-0 d-none d-md-block"><strong>Year 3 savings</strong></h5>
                                        <h6 class="mb-0 d-block d-md-none"><strong>Year 3 savings</strong></h6>
                                    </div>
                                    <div class="card-body text-center px-1">
                                        <h5 class="text-primary"><strong>&pound;{{ saving3yrDisplay }}</strong></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row justify-content-center">
                                <div class="card">
                                    <div class="card-header px-3 px-md-4">
                                        <h5 class="mb-0 d-none d-md-block"><strong>Year 10 savings</strong></h5>
                                        <h6 class="mb-0 d-block d-md-none"><strong>Year 10 savings</strong></h6>
                                    </div>
                                    <div class="card-body text-center px-1">
                                        <h5 class="text-primary"><strong>&pound;{{ saving10yrDisplay }}</strong></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <p class="col-12">Evidence shows within 3 years of providing AutonoMe support service users require on average 2 fewer hours of 1:1 care. </p>
                    </div>
                    <!-- <div class="row">
                        <p class="col-12">&ast; The National average hourly rate for home care is £16.77 - ADASS 2019 Budget Report.</p>
                    </div> -->
                </div>
            </div>
            <!-- <div class="card-footer">
            </div> -->
        </div>
    </div>
</div>
</div>
</template>
