import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import flash from './modules/flash';
// import test from './modules/test';
// import test2 from './modules/test2';

// initial state
let initialState = {
  flash: flash.state,
}

export default new Vuex.Store({
    strict: true,
    modules: {
        flash: flash,
        // t1: test,
        // t2: test2,
    },
    mutations: {
      reset (state) {
        Object.keys(state).forEach(key => {
          Object.assign(state[key], initialState[key])
        })
      }
    }
});
