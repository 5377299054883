<script>

import FormSubmitComponent from './_partials/FormSubmitComponent.vue';

export default {
    name: 'FormShowComponent',
    components: {
        FormSubmitComponent
    },
    props : {
        formData: Object,
        oldData: Object,
        model: Object,
        modelName: String,
        route: String,
        redirect: {
            type: String,
            default: ''
        },
        componentFile: {
            type: String,
            default: () => null
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false,
            formType: 'show',
        }
    },
    mounted: function() {
        if (this.modelName) {
            this.loadComponent()
        }
    },
    computed: {
        componentLoader: function() {
            // Import child component by modelName
            return () => import('../show/'+this.modelName+'/Show'+this.modelName+'Component');
        },
        formAction: function() {
            return this.route + '/' + this.model.id;
        },
        formId: function() {
            return 'form-' + this.modelName.toLowerCase() + '-edit';
        }
    },
    directives: {
    },
    methods: {
        loadComponent: function() {
            this.componentLoader().then(comp => {
                // console.log('comp', comp);
            });
        }
    }
}
</script>

<template>
    <component :is="componentLoader" :model="model" :modelName="modelName"
    :formData="formData" :formType="formType" :oldData="oldData"></component>
</template>
