<script>

import FormSubmitComponent from '../_partials/FormSubmitComponent.vue';
import FormInteractionActionComponent from './FormInteractionActionComponent.vue';
import FormInteractionTagComponent from './FormInteractionTagComponent.vue';

import Vuelidate from 'vuelidate';
import { required, requiredIf, alpha, integer, minValue, minLength } from 'vuelidate/lib/validators'

Vue.use(Vuelidate);

export default {
  components: {
    FormSubmitComponent,
    FormInteractionActionComponent,
    FormInteractionTagComponent
  },
  props: {
    formData: Object,
    oldData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    history: Array,
    modelName: String,
    route: String,
    redirect: {
      type: String,
      default: ''
    },
    componentFile: {
      type: String,
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      failed: false,
      historyIndex: 0,
      isLoaded: false,
      isLoading: false,
      resetFlag: false,
      form: {
        name: "",
      },
      interactionCurrent: JSON.parse(JSON.stringify(this.history[0])),
      interactionActionsCurrent: JSON.parse(JSON.stringify(this.history[0].interaction_actions)),
    }
  },
  computed: {
    componentLoader: function () {
      // Import child component by modelName
      return () => import('../' + this.modelName + '/Form' + this.modelName + 'Component');
    },
    componentHistoryLoader: function () {
      // Import child component by modelName
      return () => import('../' + this.modelName + '/Form' + this.modelName + 'HistoryComponent');
    },
    formAction: function () {
      return this.route + '/' + this.history[this.historyIndex].id;
    },
    formId: function () {
      return 'form-' + this.modelName.toLowerCase() + '-edit';
    },
    interactionActionData: function () {
      var interactionActions = this.history[this.historyIndex].interaction_actions;

      if (interactionActions === undefined || interactionActions.length == 0) {
        return undefined;
      } else {
        return interactionActions;
      }
    },
    interactionActionOldData: function () {
      // Cast interactionAction old data to array
      if (this.oldData) {
        if (typeof this.oldData.interactionAction !== "undefined") {
          return Object.values(this.oldData.interactionAction);
        }
      }
    },
    locked: function () {
      return (this.historyIndex > 0)
    },
    model: function () {
      return this.history[this.historyIndex];
    }
  },
  methods: {
    formReset: function (newValue, oldValue) {
      // if (newValue === true) {
      //     this.interactionCurrent = JSON.parse(JSON.stringify(this.history[0]));
      // }
    },
    formSubmit: function (formId, event) {
      // Run validations
      this.$v.interactionCurrent.$touch();
      this.$v.interactionActionsCurrent.$touch();

      // if it's still pending or an error is returned do not submit
      if (this.$v.interactionCurrent.$pending || this.$v.interactionCurrent.$error) return;
      if (this.$v.interactionActionsCurrent.$pending || this.$v.interactionActionsCurrent.$error) return;

      // console.log(this.interactionCurrent);

      document.getElementById(formId).submit();
    },
    loadComponent: function () {
      this.componentLoader().then(comp => {
        // console.log('comp', comp);
      });
      this.componentHistoryLoader().then(comp => {
        // console.log('comp', comp);
      });
    },
    reset: function (value) {
      // console.log('reset method');
      this.resetFlag = value;
      // this.historyIndex = 0;
    },
    interactionTagsChange: function (interactionTags) {
      if (interactionTags !== undefined) {
        this.interactionCurrent.interaction_tags = JSON.parse(JSON.stringify(interactionTags));
      } else {
        this.interactionCurrent.interaction_tags = null;
      }
      // Vue.set(this.interactionCurrent, 'interaction_tags', interactionTags);
    }

  },
  mounted: function () {
    if (this.modelName) {
      this.loadComponent();
    }
    this.$on('historyIndex', index => {
      this.historyIndex = index;
    });


  },
  validations: {
    interactionCurrent: {
      interaction_date: {
        required
      },
      interaction_time: {
        required
      },
      interaction_type: {
        id: {
          required,
          integer,
          minValue: minValue(1)
        }
      },
      note: {
        required
      },
      user_detail: {
        user_id: {
          required,
          integer,
          minValue: minValue(1)
        }
      }
    },
    interactionActionsCurrent: {
      $each: {
        action_date: {
          required
        },
        action_time: {
          required
        },
        admin: {
          id: {
            required,
            integer,
            minValue: minValue(1)
          }
        },
        interaction_action_type: {
          id: {
            required,
            integer,
            minValue: minValue(1)
          }
        },
        note: {
          required,
        },
        other: {
          requiredIf: requiredIf((interactionAction) => {
            return interactionAction.interaction_action_type.id == 1;
          })
        }
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form :id="formId" :action="formAction" method="post" enctype="multipart/form-data"
        class="form-horizontal form-edit">
        <slot name="csrf"></slot>
        <input type="hidden" name="_method" value="PATCH">
        <input v-if="redirect" type="hidden" id="edit-interaction-redirect" name="redirect" :value="redirect">

        <div class="row">
          <div class="col-12 col-lg-6">
            <component :is="componentLoader" :modelName="modelName" :formId="formId" @reset="reset" :resetFlag="resetFlag"
              :history="history" :historyIndex="historyIndex" :formData="formData" :oldData="oldData" :route="route"
              v-model="interactionCurrent" :v="$v.interactionCurrent"></component>
          </div>

          <div class="col-12 col-lg-6">
            <form-interaction-tag-component :resetFlag="resetFlag" :formData="formData" :historyIndex="historyIndex"
              :interactionCurrentId="interactionCurrent.id"
              @interactionTagsChange="interactionTagsChange"></form-interaction-tag-component>

            <form-interaction-action-component :modelArray="interactionActionData" :resetFlag="resetFlag"
              :formData="formData" :oldData="interactionActionOldData" :historyIndex="historyIndex"
              v-model="interactionActionsCurrent" :v="$v.interactionActionsCurrent"></form-interaction-action-component>
          </div>


        </div>
        <div class="row">
          <div class="col-12">
            <div class="card mw-100">
              <form-submit-component v-on:formReset="formReset" v-on:formSubmit="formSubmit" :modelName="modelName"
                submitFlag resetFlag :formId="formId"></form-submit-component>
            </div>
          </div>
        </div>

        <component :is="componentHistoryLoader" :history="history" :historyIndex="historyIndex" :modelName="modelName">
        </component>
      </form>
    </div>
  </div>
</template>
