// Make sure to call Vue.use(Vuex) first if using a module system

const getDefaultState = () => {
  return {
      status: null,
      data: {},
  }
}

const state = getDefaultState();

const mutations = {
    SET_DATA (state, data) {
        state.data = data;
    },
    DELETE_ERROR_ITEM (state, payload) {
// console.log('DELETE_ERROR_ITEM');
// console.log('item: ', payload.item);
// console.log('reactive: ', payload.reactive);
// console.log('state: ', state);
      if ('errors' in state.data && payload.item in state.data.errors) {
// console.log('errors key exists');
        if (payload.reactive) {
// console.log('is reactive');
              Vue.delete(state.data.errors, payload.item);
        } else {
// console.log('not reactive');
              delete state.data.errors[payload.item];
        }
      }
    },
    SET_STATUS (state, status) {
        state.status = status;
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState());
    },
}

const actions = {
    setData ({ commit, state }, data) {
        commit('SET_DATA', data);
    },
    deleteErrorItem ({ commit, state }, payload) {
        commit('DELETE_ERROR_ITEM', payload);
    },
    setResponse ({ commit, state }, response) {
// console.log('setResponse: ', response);
        commit('SET_DATA', response.data);
        commit('SET_STATUS', response.status);
    },
    resetResponse ({ commit }) {
        commit('RESET_STATE');
    },
}

const getters = {
    errors (state) {
        return state.data.errors;
    },
    message (state) {
        return state.data.message;
    },
    status (state) {
        return state.status;
    }

}

export default {
    namespaced: true,
    state,
    // getters: {},
    getters,
    actions,
    mutations,
}
