
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.moment = require('moment');

// import 'select2';
// import 'select2';
// import 'select2/dist/css/select2.css';

// require('chart.js');
// import 'datatables.net';
// import 'datatables.net-bs4';


// import 'chart.js';
import Cleave from 'cleave.js';

// window.Vue = require('vue');
import Vue from 'vue';
window.Vue = Vue;

Vue.prototype.$windowLocation = window.location;

// Vue.prototype.$globalObj = {};

import store from './store';

// import Vuetify from 'vuetify';
//    Vue.use(Vuetify); 

//    const app = new Vue({
//     el: '#app',
//     vuetify: new Vuetify(),
   
// });

// window.FontAwesomeConfig = {
//   searchPseudoElements: true
// }


var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

// import moment from 'moment';

Vue.filter('formatDate', function(value, format = 'DD/MM/YYYY HH:mm') {
    if (value) {
        return moment(String(value)).format(format);
    }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//

var componentSelectContractUser = require('./components/selects/SelectUserByContractComponent.vue').default;
var componentFormCreate = require('./components/forms/FormCreateComponent.vue').default;
var componentFormCreateInteraction = require('./components/forms/Interaction/FormCreateInteractionComponent.vue').default;
var componentFormEditInteractionHistory = require('./components/forms/Interaction/FormEditInteractionHistoryComponent.vue').default;
var componentFormEdit = require('./components/forms/FormEditComponent.vue').default;
var componentFormTabs = require('./components/forms/FormTabsComponent.vue').default;
var componentFormShow = require('./components/forms/FormShowComponent.vue').default;
var componentFlash = require('./components/layout/FlashComponent.vue').default;

var componentTest = require('./components/TestComponent.vue').default;
var componentLoader = require('./components/LoaderComponent.vue').default;
var componentCostCalculator = require('./components/web/CostCalculatorComponent.vue').default;

window.app = new Vue({
    el: '#vue-app',
    store,
    components: {
        'select-contract-user-component': componentSelectContractUser,
        'flash-component': componentFlash,
        'form-create-component': componentFormCreate,
        'form-create-interaction-component': componentFormCreateInteraction,
        'form-edit-component': componentFormEdit,
        'form-tabs-component': componentFormTabs,
        'form-edit-interaction-history-component': componentFormEditInteractionHistory,
        'form-show-component': componentFormShow,
        'test-component': componentTest,
        'loader-component': componentLoader,
        'cost-calculator-component': componentCostCalculator,
    }
});

// Vue devtools 'open in editor'
// const launchMiddleware = require('launch-editor-middleware');
// app.use('/__open-in-editor', launchMiddleware());

import '@coreui/coreui-pro';
import '@coreui/coreui-plugin-chartjs-custom-tooltips';

// Random color
// window.randomColor = require('randomcolor');

// Custom JS
//

// window.onbeforeprint = am_beforePrint;

// Resize Google Chart when sidebar is toggled

$('.sidebar-toggler').on('click', function (event) {
    am_redrawGoogleChart();
});

function am_beforePrint() {
    console.log('BeforePrint');
    am_redrawGoogleChart();
}

function am_redrawGoogleChart() {
    var ev = document.createEvent('Event');
    ev.initEvent('resize', true, true);
    window.dispatchEvent(ev);
}