<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'SelectUserByContractComponent',
    components: {
        Multiselect,
    },
    props: {
        model: {
            type: Object,
            default: null
        },
    },
    data: function() {
        return {
            isLoaded: {
                contractUsers: false,
            },
            isLoading: {
                contractUsers: false,
            },
            isFailed: {
                contractUsers: false,
            },
            contractUsers: {
                data: [],
            },
            selectedContract: null,
            selectedUser: {
                id: this.model.id,
                name: this.model.name,
                // usageStatus: this.model.usageStatus,
								usageStatusDot: this.model.usageStatusDot,
            },
            test: true,
        }
    },
    computed: {
        contractInitialValue: function() {
            return this.contractUsers.data[0];
        },
        selectedContractProxy: {
            get: function() {
                if (this.selectedContract === null) {
                    return this.contractInitialValue;
                } else {
                    return this.selectedContract;
                }

            },
            set: function(newValue) {
                this.selectedContract = newValue;
            }
        },

        userOptions: function() {
            if (this.selectedContractProxy) {
                return this.selectedContractProxy.users;
            } else {
                return [];
            }
        }
    },
    directives: {},
    methods: {
        getContractUsers: function() {
            this.isLoading.contractUsers = true;

            // Get user data
            window.axios.get('/admin/contracts/users/json').then(({data}) => {
                this.contractUsers.data = data;
                this.isLoading.contractUsers = false;

                if (data === undefined || data.length == 0) {
                    this.isFailed.contractUsers = true;
                    console.log(error);
                } else {
                    this.isLoaded.contractUsers = true;
                }

            }).catch(error => {
                this.isLoading.contractUsers = false;
                this.isFailed.contractUsers = true;

                console.log('error', error);
                // if (error.response.status === 401) {
                //     window.location.href = '/login';
                // }
            });
        },
        changeContract: function(contract) {
            this.$refs.selectorUser.$el.focus()
        },
        changeUser: function(user) {
          console.log('user: ', user);
            let regex = /(.+users\/)\d+(.+)?/;

            let targetUrl = window.location.href.replace(regex, '$1' + user.user_id + '$2');

            window.location.href = targetUrl;
        },
        // getUserUsageStatus: function(user) {
        //     let usageClass;
        //
        //     switch (user.usageStatus) {
        //         case 'off':
        //             usageClass = "fas fa-times-circle am_dot text-dark";
        //             break;
        //         case 'superActive':
        //             usageClass = "fas fa-plus-circle am_dot text-success";
        //             break;
        //         case 'active':
        //             usageClass = "fas fa-circle am_dot text-success";
        //             break;
        //         case 'never':
        //             usageClass = "fas fa-minus-circle am_dot text-danger";
        //             break;
        //         default:
        //             usageClass = "fas fa-circle am_dot text-danger";
        //     }
        //
        //     return usageClass;
        // },
    },
    mounted: function() {
        // get contracts and associated users
        this.getContractUsers();
    },
}
</script>

<template>
    <div class="selectors row mx-0 text-right" v-if="this.isLoaded.contractUsers" dusk="select-user-by-contract-component">
        <multiselect id="selector-contract" name="" v-model="selectedContractProxy"
         class="col-12 mb-2 col-md px-md-0 mr-md-2 multiselect-form-control-sm" :options="contractUsers.data" openDirection="below"
         track-by="id" label="name" :searchable="true" :show-labels="false" @select="changeContract">
        </multiselect>

        <multiselect id="selector-user" name="" v-model="selectedUser" ref="selectorUser"
         class="col-12 mb-2 col-md px-md-0 multiselect-form-control-sm" :options="userOptions" openDirection="below"
         track-by="user_id" label="name" :searchable="true" :show-labels="false"
         @select="changeUser">
             <template slot="singleLabel" slot-scope="props">
                 <span class="option__desc">
                     <span class="option__title position-relative">
											 	{{ props.option.name }}
                        <i :class="props.option.usageStatusDot"></i>
                     </span>
                 </span>
             </template>
             <template slot="option" slot-scope="props">
                 <div class="option__desc">
                     <span class="option__title position-relative">
											 	{{ props.option.name }}
                        <i :class="props.option.usageStatusDot"></i>
                     </span>
                 </div>
             </template>
        </multiselect>


        <!-- <multiselect v-model="value" placeholder="Fav No Man’s Sky path" label="title" track-by="title"
         :options="options" :option-height="104" :custom-label="customLabel" :show-labels="false">
          <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="props.option.img" alt="No Man’s Sky"><span class="option__desc"><span class="option__title">{{ props.option.title }}</span></span></template>
          <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.img" alt="No Man’s Sky">
            <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span class="option__small">{{ props.option.desc }}</span></div>
          </template>
        </multiselect> -->
    </div>
</template>
