<script>

import FormInteractionActionComponent from './FormInteractionActionComponent.vue';
import FormInteractionTagComponent from './FormInteractionTagComponent.vue';
import FormSubmitComponent from '../_partials/FormSubmitComponent.vue';

import Vuelidate from 'vuelidate';
import { required, requiredIf, alpha, integer, minValue, minLength } from 'vuelidate/lib/validators'

Vue.use(Vuelidate);

export default {
  components: {
    FormSubmitComponent,
    FormInteractionActionComponent,
    FormInteractionTagComponent
  },
  props: {
    formAction: String,
    formData: Object,
    oldData: {
      type: Object,
      default: null
    },
    presets: Object,
    modelName: String,
    route: String,
    redirect: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      isLoaded: false,
      isLoading: false,
      failed: false,
      resetFlag: false,
      historyIndex: 0,
      interactionCurrent: {
        admin: {
          id: null,
          name: null
        },
        interaction_tags: [],
        interaction_type: {
          id: null,
          type: null
        },
        user_detail: {
          user_id: null,
          name: null
        },
        interaction_date: moment().format('YYYY-MM-DD'),
        // interaction_time: '09:00',
        interaction_time: moment().format('HH:mm'),
        pinned: 0,
        note: null,
        version: 1
      },
      interactionActionsCurrent: [],
    }
  },
  computed: {
    componentLoader: function () {
      // Import child component by modelName
      return () => import('../' + this.modelName + '/Form' + this.modelName + 'Component');

    },
    componentOldData: function () {
      if (Object.keys(this.oldData).length === 0 && this.oldData.constructor === Object) {
        return undefined;
      } else {
        return this.oldData;
      }
    },
    formId: function () {
      return 'form-' + this.modelName.toLowerCase() + '-create';
    },
    interactionActionData: function () {
      // var interactionActions = this.history[this.historyIndex].interaction_actions;
      //
      // if (interactionActions === undefined || interactionActions.length == 0) {
      return undefined;
      // } else {
      //     return interactionActions;
      // }
    },
    interactionActionOldData: function () {
      // Cast interactionAction old data to array
      if (this.oldData) {
        if (typeof this.oldData.interactionAction !== "undefined") {
          return Object.values(this.oldData.interactionAction);
        }
      }
    },
  },
  methods: {
    formReset: function (newValue, oldValue) {
      // if (newValue === true) {
      //     this.interactionCurrent = JSON.parse(JSON.stringify(this.history[0]));
      // }
    },
    formSubmit: function (formId, event) {
      // Run validations
      this.$v.interactionCurrent.$touch();
      this.$v.interactionActionsCurrent.$touch();

      // if its still pending or an error is returned do not submit
      if (this.$v.interactionCurrent.$pending || this.$v.interactionCurrent.$error) return;
      if (this.$v.interactionActionsCurrent.$pending || this.$v.interactionActionsCurrent.$error) return;

      document.getElementById(formId).submit();
    },
    loadComponent: function () {
      this.componentLoader().then(comp => {
        // console.log('comp', comp);
      });
    },
    reset: function (value) {
      this.resetFlag = value;
    },
    interactionTagsChange: function (interactionTags) {
      console.log(interactionTags);
      if (interactionTags !== undefined) {
        this.interactionCurrent.interaction_tags = JSON.parse(JSON.stringify(interactionTags));
      } else {
        this.interactionCurrent.interaction_tags = null;
      }
      // Vue.set(this.interactionCurrent, 'interaction_tags', interactionTags);
      console.log('inteeractiontAgsChange');
    }
  },
  mounted: function () {
    if (this.modelName) {
      this.loadComponent()
    }
  },
  validations: {
    interactionCurrent: {
      interaction_date: {
        required
      },
      interaction_time: {
        required
      },
      interaction_type: {
        id: {
          required,
          integer,
          minValue: minValue(1)
        }
      },
      note: {
        required
      },
      pinned: {
        integer,
      },
      user_detail: {
        user_id: {
          required,
          integer,
          minValue: minValue(1)
        }
      }
    },
    interactionActionsCurrent: {
      // required,
      $each: {
        action_date: {
          required
        },
        action_time: {
          required
        },
        admin: {
          id: {
            required,
            integer,
            minValue: minValue(1)
          }
        },
        interaction_action_type: {
          id: {
            required,
            integer,
            minValue: minValue(1)
          }
        },
        note: {
          required,
        },
        other: {
          requiredIf: requiredIf((interactionAction) => {
            return interactionAction.interaction_action_type.id == 1;
          })
        }
      }
    }
  }
}
</script>

<template>
  <form :id="formId" :action="formAction" method="post" enctype="multipart/form-data" class="form-horizontal form-create">
    <slot name="csrf"></slot>
    <input v-if="redirect" type="hidden" id="create-interation-redirect" name="redirect" :value="redirect">
    <div class="row">
      <div class="col-12 col-lg-6">
        <component :is="componentLoader" :presets="presets" :modelName="modelName" :formId="formId" @reset="reset"
          :resetFlag="resetFlag" :formData="formData" :oldData="oldData" :route="route" v-model="interactionCurrent"
          :v="$v.interactionCurrent"></component>
      </div>

      <div class="col-12 col-lg-6">
        <form-interaction-tag-component :resetFlag="resetFlag" :formData="formData" formType="Create" :historyIndex="historyIndex"
          :interactionCurrentId="interactionCurrent.id" 
          @interactionTagsChange="interactionTagsChange"></form-interaction-tag-component>
        <form-interaction-action-component :modelArray="interactionActionData" :resetFlag="resetFlag" :formData="formData"
          :oldData="interactionActionOldData" v-model="interactionActionsCurrent"
          :v="$v.interactionActionsCurrent"></form-interaction-action-component>
      </div>
    </div>
    <div class="card">
      <form-submit-component v-on:formReset="formReset" v-on:formSubmit="formSubmit" :modelName="modelName" submitFlag
        resetFlag :formId="formId"></form-submit-component>
    </div>
  </form>
</template>
