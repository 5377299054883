<script>

import FlashErrorComponent from './_partials/FlashErrorComponent.vue';
import { mapState } from 'vuex'

export default {
    name: 'FlashComponent',
    components: {FlashErrorComponent},
    props : {},
    data: function() {
        return {
          isLoaded: false,
          isLoading: false,
          failed: false,
          refresh: false,
        }
    },
    mounted: function() {},
    computed: {
      ...mapState('flash', {
        data: state => state.data,
        status: state => state.status,
      }),
    },
    watch: {},
    directives: {},
    methods: {},
    created: function () {
      this.unsubscribe = this.$store.subscribeAction((action, state) => {
        if (action.type === 'flash/setResponse') {
          this.refresh = !this.refresh;
        }
      });

      this.isLoaded = true;

    },
    beforeDestroy: function() {
      this.unsubscribe();
    },
}
</script>

<template>
    <flash-error-component
      :alertClass="data.alertClass"
      :errors="data.errors"
      :status="status"
      :message="data.message">
    </flash-error-component>

</template>
