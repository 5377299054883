<script>
// import LoaderComponent from './LoaderComponent.vue';

export default {
    components: {
        // LoaderComponent,
    },
    data: function() {
        return {
            // isLoaded: false,
            // isLoading: false,
            // failed: false,
        }
    },
    computed: {
    },
    methods: {
    },
    mounted: function() {
        // this.isLoading = true;
        // this.isLoaded = false;
    }
}
</script>

<template>
<!-- <div class="card"> -->
    <!-- <div class="card-header" data-toggle="collapse" href="#collapseOutcomes" aria-expanded="false" aria-controls="collapseOutcomes"> -->
        <strong>
            <h4>Test Component</h4>
        </strong>
    <!-- </div> -->
    <!-- <loader-component :componentPath="'forms/FormInteractionComponent'" :componentProps="{ formId: 'formIdTest', data2: 2, data3: 3}" ></loader-component>
    <loader-component :componentPath="'forms/FormInteractionActionComponent'" :componentProps="{ data1: 4, data2: 5, data3: 6}" ></loader-component> -->
<!-- </div> -->
</template>
