<script>

// import FormSubmitComponent from './_partials/FormSubmitComponent.vue';

export default {
    components: {
        // FormSubmitComponent,
    },
    props : {
        formAction: String,
        formConfig: Object,
        formData: Object,
        flashErrorData: Object,
        isSuperAdmin: Boolean,
        adminRoles: Array,
        oldData: {
            type: Object,
            default: null
        },
        model: Object,
        modelName: String,
        route: String,
        redirect: {
            type: String,
            default: ''
        }
        // componentFile: {
        //     type: String,
        //     default: () => null
        // },
        // options: {
        //     type: Array,
        //     default: () => []
        // }
        // },
        // slot: {
        //     type: String,
        //     default: () => null
        // }
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false,
            resetFlag: false,
            formType: 'create',
            // outcomes: [],
            // userId: false,
            // dashboardContractSelect: false,
            // dashboardUserSelect: false,
            // videoAnalytics: [],
            // meta: []
        }
    },
    computed: {
        componentLoader: function() {
            // Import child component by modelName
            // return () => import('./'+this.modelName+'/Form'+this.modelName+'Component');
            return () => import(this.formDirectory+this.modelName+'/Form'+this.modelName+'Component');
        },
        componentOldData: function() {
            if (Object.keys(this.oldData).length === 0 && this.oldData.constructor === Object) {
                // return undefined;
                return this.oldData;
            } else {
                return this.oldData;
            }
        },
        formDirectory: function() {
            if (this.formConfig && this.formConfig.formDirectory) {
              return './'+this.formConfig.formDirectory+'/';
            } else {
              return './';
            }
        },
        formId: function() {
            return 'form-' + this.modelName.toLowerCase() + '-create';
        },
        // If model is falsey or an empty object
        // return undefined
        // This will cause the component being loaded to use the prop model default
        modelObject: function() {
            return (this.model && Object.keys(this.model).length !== 0 && this.model.constructor === Object)
              ? this.model
              : undefined;
        }
    },
    directives: {
        // select2: directiveSelect2
    },
    methods: {
        loadComponent: function() {
            this.componentLoader().then(comp => {
                // console.log('comp', comp);
            });
        },
        reset: function(value) {
            console.log('reset method2');
            console.log('before', this.resetFlag);
            this.resetFlag = value;
            console.log('after', this.resetFlag);

            // this.historyIndex = 0;
        }
    },
    mounted: function() {
        if (this.modelName) {
            this.loadComponent()
        }
    }
}
</script>

<template>
<div>
    <component
      :is="componentLoader"
      :model="modelObject"
      :modelName="modelName"
      :isSuperAdmin="isSuperAdmin"
      :adminRoles="adminRoles"
      :redirect="redirect"
      :route="route"
      :formConfig="formConfig"
      :formData="formData"
      :formType="formType"
      :oldData="oldData"
      :flashErrorData="flashErrorData">
    </component>
</div>

</template>
