<script>

import Multiselect from 'vue-multiselect';
// import directiveSelect2 from '../directives/select2';
import Vuelidate from 'vuelidate';
import { required, requiredIf, integer, minValue, minLength } from 'vuelidate/lib/validators'

Vue.use(Vuelidate);

export default {
    components: {
        Multiselect
    },
    props : {
        formData: Object,
        oldData: {
            type: Array
        },
        historyIndex: {
            type: Number,
            default: 0
        },
        modelArray: {
            type: Array,
            default: function () {
                return [];
                // return [{
                //     id: 1,
                //     actionAt: null,
                //     action_date: moment().format('YYYY-MM-DD'),
                //     action_time: '09:00',
                //     // actionBy: this.model.admin_id,
                //     actionBy: null,
                //     admin: {
                //         id: null
                //     },
                //     interactionActionType_id: null,
                //     interaction_action_type: {
                //         id: null
                //     },
                //     note: null,
                //     other: null
                // }];
            }
        },
        resetFlag: Boolean,
        value: {
          type: Array,
          default: []
        },
        v: {
          type: Object,
          required: true
        }
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false,
            defaultAction: {
                id: 1,
                actionAt: null,
                action_date: moment().format('YYYY-MM-DD'),
                action_time: '09:00',
                // actionBy: this.model.admin_id,
                actionBy: null,
                admin: {
                    id: null
                },
                interactionActionType_id: null,
                interaction_action_type: {
                    id: null
                },
                note: null,
                other: null
            },
            // interactionActionsCurrent: JSON.parse(JSON.stringify(this.modelArray)),
            interactionActionsEdit: false
        }
    },
    computed: {
        actionCount: function() {
            return this.interactionActionsCurrent.length;
        },
        // normalizedModelArray: function() {
        //     return Object.assign({
        //       // ellipsis: '...'/*, other default values*/
        //     }, this.modelArray)
        //     // return this.modelArray;
        // },
        disableButtonAddAction: function() {
            if (this.actionCount > 0) {
                var lastAction = this.interactionActionsCurrent[this.actionCount - 1];
                if (lastAction.interaction_action_type.id === null) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        interactionActionsCurrent: {
                get() {
                  return this.value;
                },
                set(value) {
                  this.$emit("input", value);
                }
        },
        interactionActionsNextId: function() {
            if (this.actionCount > 0) {
                var maxId = Math.max.apply(Math, this.interactionActionsCurrent.map(function(interactionAction) {
                    return interactionAction.id + 1;
                }));
                return maxId;
            } else {
                return 1;
            }
        },
        locked: function() {
            return (this.historyIndex > 0)
        }
    },
    methods: {
        addInteractionAction: function () {
            let newAction = JSON.parse(JSON.stringify(this.defaultAction));
            newAction.id = this.interactionActionsNextId;
            this.interactionActionsCurrent.push(newAction);
        },
        deleteInteractionAction: function (index) {
            this.interactionActionsCurrent.splice(index, 1);
            // if (this.interactionActionsCurrent.length === 0) {
            //   this.addInteractionAction()
            // }
        }
    },
    mounted: function() {
        if (this.historyIndex === 0) {
            if (this.oldData) {

                // var oldData = this.oldData;
                var formData = this.formData;
                var interactionActionsCurrent = this.interactionActionsCurrent;

                this.oldData.forEach(function (oldAction, index) {

                    // old interactionActionType_id
                    if (oldAction.interactionActionType_id) {
                        let interactionActionTypeId = formData.interactionActionTypes.find(function(element) {
                            return element.id == oldAction.interactionActionType_id;
                        });
                        interactionActionsCurrent[index].interaction_action_type = interactionActionTypeId;
                    } else {
                        interactionActionsCurrent[index].interaction_action_type = {
                            id: null
                        }
                    }

                    // old action_at
                    interactionActionsCurrent[index].action_at = oldAction.action_at;
                    interactionActionsCurrent[index].action_date = oldAction.action_date;
                    interactionActionsCurrent[index].action_time = oldAction.action_time;

                    // old action_by
                    if (oldAction.interactionActionType_id) {
                        let actionBy = formData.admins.find(function(element) {
                            return element.id == oldAction.action_by;
                        });
                        interactionActionsCurrent[index].admin = actionBy;
                    } else {
                        interactionActionsCurrent[index].admin = {
                            id: null,
                            name: null
                        }
                    }

                    // note
                    interactionActionsCurrent[index].note = oldAction.note;

                });
            } else {
                // this.interactionActionsCurrent = JSON.parse(JSON.stringify(this.modelArray));
            }
        } else {
            // this.interactionActionsCurrent = [JSON.parse(JSON.stringify(this.modelArray))];
            this.interactionActionsCurrent.push(this.defaultAction);
        }
    },
    watch: {
        historyIndex: function (newValue, oldValue) {
            if (oldValue === 0) {
                this.interactionActionsEdit = this.interactionActionsCurrent;
            }

            // update displayed version
            if (newValue === 0) {
                this.interactionActionsCurrent = this.interactionActionsEdit;
            } else {
                this.interactionActionsCurrent = JSON.parse(JSON.stringify(this.modelArray));
            }
        },
        // resetFlag: function (newValue, oldValue) {
        //     if (newValue === true) {
        //         this.interactionActionsCurrent = JSON.parse(JSON.stringify(this.modelArray));
        //         this.$nextTick( () => {
        //             this.$emit('reset', false);
        //         });
        //     }
        //
        //     // this.resetFlag = false;
        // }
    },
    // validations: {
    //     interactionActionsCurrent: {
    //         // required,
    //         $each: {
    //             action_date: {
    //                 required
    //             },
    //             action_time: {
    //                 required
    //             },
    //             actionBy: {
    //                 id: {
    //                     required,
    //                     integer,
    //                     minValue: minValue(1)
    //                 }
    //             },
    //             admin: {
    //                 id: {
    //                     required,
    //                     integer,
    //                     minValue: minValue(1)
    //                 }
    //             },
    //             interaction_action_type: {
    //                 id: {
    //                     required,
    //                     integer,
    //                     minValue: minValue(1)
    //                 }
    //             },
    //             note: {
    //                 required
    //             },
    //             other: {
    //                 requiredIf: requiredIf((vueInstance) => {
    //                   	console.log(vueInstance);
    //                     return true;
    //                 })
    //             }
    //         }
    //     }
    // }
}
</script>

<template>
    <!-- <div class="col-lg-12"> -->
        <!-- <div id="form-interactionAction-wrapper"> -->
        <div id="form-interactionAction-wrapper" class="card">
            <div class="card-header">
                <strong>Actions</strong>
            </div>
            <div class="card-body">
                <transition-group name="">
                    <div v-for="(interactionAction, index) in interactionActionsCurrent" :key="interactionAction.id"
                     :id="'interactionActionIndex'+index">
                        <div class="form-row mt-3">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm"
                             :for="'interactionActionSelect['+index+'][interactionActionType_id]'">
                                <i class="fas fa-minus-circle text-danger hover-effect" @click="deleteInteractionAction(index)"></i>
                                <h5 class="card-title d-inline" :id="'interactionAction'+(index + 1)">Action #{{index + 1}}</h5>
                            </label>
                            <div class="col-md-9">
                                <input type="hidden" :id="'interactionAction['+index+'][interactionActionType_id]'"
                                 :name="'interactionAction['+index+'][interactionActionType_id]'"
                                 :value="interactionActionsCurrent[index].interaction_action_type.id" required>

                                <multiselect :id="'interactionActionSelectId['+index+'][interactionActionType_id]'"
                                  :name="'interactionActionSelectName['+index+'][interactionActionType_id]'"
                                  class="multiselect-form-control-sm"
                                  v-model="interactionAction.interaction_action_type" :options="formData.interactionActionTypes"
                                  track-by="id" label="type" :searchable="true" :disabled="locked"
                                  :show-labels="false"
                                  :class="{ 'is-invalid': v.$each[index].interaction_action_type.id.$invalid,
                                  'is-valid': !v.$each[index].interaction_action_type.id.$invalid }">
                                </multiselect>

                                <!-- <em id="'interactionAction['+index+'][interaction_action_type]'-error" class="error invalid-feedback"
                                 v-if="!$v.interactionActionsCurrent.$each[index].interaction_action_type.id.required">Field is required</em> -->
                            </div>
                        </div>

                        <div class="form-row mt-3" v-if="interactionActionsCurrent[index].interaction_action_type.id === 1">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm"
                                :for="'interactionAction['+index+'][other]'">Other</label>
                            <div class="col-12 col-md-9">
                                <input type="text" :id="'interactionAction['+index+'][other]'"
                                    :name="'interactionAction['+index+'][other]'"
                                    v-model="interactionAction.other" :disabled="locked"
                                    class="form-control form-control-sm" placeholder="Type Action..." value=""
                                    :class="{ 'is-invalid': v.$each[index].other.$invalid,
                                    'is-valid': !v.$each[index].other.$invalid }" />
                                <!-- <em id="'interactionAction['+index+'][other]'-error" class="error invalid-feedback"
                                 v-if="!$v.interactionActionsCurrent.$each[index].other.required">Field is required</em> -->
                            </div>
                        </div>

                        <input type="hidden" :id="'interactionAction['+index+'][action_at]'"
                            :name="'interactionAction['+index+'][action_at]'"
                            :value="interactionAction.action_date+' '+interactionAction.action_time" required>

                        <div class="form-row mt-3">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm"
                                :for="'interactionAction['+index+'][action_date]'">Action Date</label>
                            <div class="col-12 col-md-9">
                                <input type="date" :id="'interactionAction['+index+'][action_date]'"
                                    :name="'interactionAction['+index+'][action_date]'" v-model="interactionAction.action_date"
                                    class="form-control form-control-sm" :disabled="locked" required
                                    :class="{ 'is-invalid': v.$each[index].action_date.$invalid,
                                    'is-valid': !v.$each[index].action_date.$invalid }" />
                                <!-- <em id="'interactionAction['+index+'][action_date]'-error" class="error invalid-feedback"
                                 v-if="!$v.interactionActionsCurrent.$each[index].action_date.required">Field is required</em> -->
                            </div>
                        </div>

                        <div class="form-row mt-3">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm"
                                :for="'interactionAction['+index+'][action_time]'">Action Time</label>
                            <div class="col-12 col-md-9">
                                <input type="time" :id="'interactionAction['+index+'][action_time]'" placeholder="09:00"
                                    :name="'interactionAction['+index+'][action_time]'" v-model="interactionAction.action_time"
                                    class="form-control form-control-sm" :disabled="locked"
                                    :class="{ 'is-invalid': v.$each[index].action_time.$invalid,
                                    'is-valid': !v.$each[index].action_time.$invalid }" />
                                <!-- <em id="'interactionAction['+index+'][action_time]'-error" class="error invalid-feedback"
                                 v-if="!$v.interactionActionsCurrent.$each[index].action_time.required">Field is required</em> -->
                            </div>
                        </div>

                        <div class="form-group form-row mt-3">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm"
                             :for="'interactionActionSelect['+index+'][action_by]'">Assigned To</label>
                            <div class="col-md-9">
                                <input type="hidden" :id="'interactionAction['+index+'][action_by]'"
                                :name="'interactionAction['+index+'][action_by]'" :value="interactionAction.admin.id" required>
                                <multiselect :id="'interactionActionSelect['+index+'][action_by]'"
                                    :name="'interactionActionSelect['+index+'][action_by]'"
                                    class="multiselect-form-control-sm"
                                    v-model="interactionAction.admin" :options="formData.admins"
                                    track-by="id" label="name" :searchable="true" :disabled="locked"
                                    :show-labels="false"
                                    :class="{ 'is-invalid': v.$each[index].admin.id.$invalid,
                                    'is-valid': !v.$each[index].admin.id.$invalid }">
                                </multiselect>
                                <!-- <em id="'interactionAction['+index+'][action_by]'-error" class="error invalid-feedback"
                                 v-if="!$v.interactionActionsCurrent.$each[index].admin.id.required">Field is required</em> -->
                            </div>
                        </div>

                        <div class="form-group form-row">
                            <label class="col-12 col-md-3 col-form-label col-form-label-sm" :for="'interactionAction['+index+'][note]'">Note</label>
                            <div class="col-12 col-md-9">
                                <textarea class="form-control form-control-sm" :id="'interactionAction['+index+'][note]'"
                                    :name="'interactionAction['+index+'][note]'" :disabled="locked"
                                    v-model="interactionAction.note" rows="5" placeholder="Note" required
                                    :class="{ 'is-invalid': v.$each[index].note.$invalid,
                                    'is-valid': !v.$each[index].note.$invalid }">
                                 </textarea>
                                 <!-- <em id="'interactionAction['+index+'][note]'-error" class="error invalid-feedback"
                                  v-if="!$v.interactionActionsCurrent.$each[index].note.required">Field is required</em> -->
                            </div>
                        </div>
                        <hr/>
                    </div>
                </transition-group>
                <div v-if="!locked" class="form-group row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-success" @click="addInteractionAction" :disabled="disableButtonAddAction">
                            Add an Action <i class="fas fa-plus-circle"></i></button>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->

</template>
