<script>

// import directiveSelect2 from './directives/select2';

export default {
    name: 'FormTabsComponent',
    components: {
    },
    props : {
        // formAction: String,
        formData: Object,
        flashErrorData: Object,
        oldData: Object,
        model: Object,
        modelName: String,
        route: String,
        redirect: {
            type: String,
            default: ''
        },
        componentFile: {
            type: String,
            default: () => null
        },
        options: {
            type: Array,
            default: () => []
        },
        formType: {
          type: String,
          default: 'edit'
        }
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,
            failed: false,
            // formType: 'edit',
        }
    },
    mounted: function() {
        if (this.modelName) {
            this.loadComponent();
        }
    },
    computed: {
        componentLoader: function() {
            // Import child component by modelName
            return () => import('./'+this.modelName+'/'+this.modelName+'TabsComponent');
        }
    },
    directives: {
        // select2: directiveSelect2
    },
    methods: {
        loadComponent: function() {
            let _this = this;
            this.componentLoader().then(comp => {
                _this.isLoaded = true;
                // console.log('comp', comp);
            });
        }
    }
}
</script>

<template>
    <component :is="componentLoader" :model="model" :modelName="modelName" :redirect="redirect" :route="route"
    :flashErrorData="flashErrorData" :formData="formData" :formType="formType" :oldData="oldData" dusk="form-tabs-component"></component>
</template>
