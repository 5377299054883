<script>

import { f } from 'v-calendar/lib/components/date-picker.common';
import Multiselect from 'vue-multiselect';
// import directiveSelect2 from '../directives/select2';
import Vuelidate from 'vuelidate';
import { required, requiredIf, integer, minValue, minLength } from 'vuelidate/lib/validators'

Vue.use(Vuelidate);

export default {
  components: {
    Multiselect
  },
  props: {
    formData: Object,
    // interactionCurrent: Object,
    interactionCurrentId: Number,
    historyIndex: {
      type: Number,
      default: 0
    },
    modelArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    formType: {
      type: String,
      default: 'Edit',
    },
    resetFlag: Boolean,

  },
  data: function () {
    return {
      isLoaded: false,
      isLoading: false,
      failed: false,
      isInteractionTagEditor: false,
      interactionTags: [],
      interactionTagsAll: [],
      disableInteractionTags: false,
    }
  },
  computed: {
    locked: function () {
      return (this.historyIndex > 0)
    }
  },
  methods: {
    // add new tag
    addTagToDB(newTag) {
      window.axios.post('/admin/interactionTags/add', {
        name: newTag
      })
        .then((response) => {
          if (response.data.flash.status === 'success') {

            this.interactionTagsAll = response.data.interactionTagsAll;
            this.addTagToInteraction(response.data.newTag);
          }
          if (response.data.flash) {
            this.$store.dispatch('flash/setResponse', response.data.flash);
          }
        })
        .catch((error) => {
          console.log(error);
          // if (typeof error.response.status === 'number') {
          //   error.response.status = '' + error.response.status;
          // }
          if (error.response) {
            this.$store.dispatch('flash/setResponse', error.response);
          }

        }).finally(() => {
        });
    },
    removeTagFromDb: function (tag) {
      if (confirm("This will delete the tag '" + tag.name + "' from all interactions and it will not be available to select.\n\nDo you really want to do this?")) {

        window.axios.post('/admin/interactionTags/delete', {
          id: tag.id,
          name: tag.name,
          interactionId: this.interactionCurrentId,
        })
          .then((response) => {
            this.interactionTagsAll = response.data.interactionTagsAll;
            this.interactionTags = response.data.interactionTags;

            if (response.data.flash) {
              this.$store.dispatch('flash/setResponse', response.data.flash);
            }
          })
          .catch((error) => {
            // console.log('error');
            // console.log(error);
            // if (typeof error.response.status === 'number') {
            //   error.response.status = '' + error.response.status;
            // }
            if (error.response) {
              this.$store.dispatch('flash/setResponse', error.response);
            }

          }).finally(() => {
            // 
            this.$emit("interactionTagsChange", this.interactionTags);
          });
      }
    },
    saveInteractionTags: function () {

      // if formType is Create
      if (this.formType === 'Create') {
        this.$emit("interactionTagsChange", this.interactionTags);
      } else {


        window.axios.post('/admin/interactions/' + this.interactionCurrentId + '/attachInteractionTags', {
          interactionTags: this.interactionTags,
        })
          .then((response) => {
            this.interactionTags = response.data.interactionTags;
            if (response.data.flash) {
              this.$store.dispatch('flash/setResponse', response.data.flash);
            }
          })
          .catch((error) => {
            // console.log('here');
            // console.log(error.response.data.flash);
            // if (typeof error.response.status === 'number') {
            //   error.response.status = '' + error.response.status;
            // }
            if (error.response) {
              this.$store.dispatch('flash/setResponse', error.response.data.flash);
            }

          }).finally(() => {
            // emit changes to parent component
            this.$emit("interactionTagsChange", this.interactionTags);
            // this.interaction.interaction_tags = this.interactionTags;
          });
      }
    },
    addTagToInteraction: function (tag) {
      this.interactionTags.push(tag);
    },
    deleteTagfromInteraction: function (tag) {
      let tagToDelete = this.interactionTags.find(e => {
        return e.id === tag.id;
      })
      this.interactionTags.splice(this.interactionTags.indexOf(tagToDelete), 1);
    },
    toggleTagInInteraction: function (tag) {
      if (!this.disableInteractionTags) {
        let tagIndex = this.interactionTags.find(e => e.id === tag.id);

        if (tagIndex !== undefined) {
          this.deleteTagfromInteraction(tag);
        } else {
          this.addTagToInteraction(tag);
        }
      }
    },
    fetchInteractionTags: function () {
      window.axios.get('/admin/interactions/' + this.interactionCurrentId + '/tags')
        .then((response) => {
          this.interactionTags = response.data.interactionTags;
          this.isInteractionTagEditor = response.data.isInteractionTagEditor;
        })
        .catch((error) => {
          // if (typeof error.response.status === 'number') {
          //   error.response.status = '' + error.response.status;
          // }
          if (error.response) {
            this.$store.dispatch('flash/setResponse', error.response);
          }
        }).finally(() => {
        });
    },
    // fetch a list of all Interaction Tags from db
    fetchInteractionTagsAll: function () {
      window.axios.get('/admin/interactionTags/fetchAll')
        .then((response) => {
          this.interactionTagsAll = response.data.interactionTagsAll;
        })
        .catch((error) => {
          // if (typeof error.response.status === 'number') {
          //   error.response.status = '' + error.response.status;
          // }
          if (error.response) {
            this.$store.dispatch('flash/setResponse', error.response);
          }

        }).finally(() => {
        });
    },
    // fetch tag editor role for current user
    fetchTagEditorRole: function () {
      window.axios.get('/admin/interactions/tagEditorRole')
        .then((response) => {
          this.isInteractionTagEditor = response.data.isInteractionTagEditor;
        })
        .catch((error) => {
          // if (typeof error.response.status === 'number') {
          //   error.response.status = '' + error.response.status;
          // }
          if (error.response) {
            this.$store.dispatch('flash/setResponse', error.response);
          }

        }).finally(() => {
        });
    },

  },
  mounted: function () {
    // Get list of all Interaction Tags
    this.fetchInteractionTagsAll();

    // Get tags for this interaction
    if (this.formType === 'Edit') {
      this.fetchInteractionTags();
    } else {
      this.fetchTagEditorRole();
    }
  },
  watch: {
    historyIndex: function (newValue, oldValue) {
      // Disable tag editting on old versions of interactions 
      if (newValue === 0) {
        this.disableInteractionTags = false;
      } else {
        this.disableInteractionTags = true;
      }
    },
    interaction: function (newValue, oldValue) {
      // Get tags for this interaction
      this.fetchInteractionTags();
    }

  },

}
</script>

<template>
  <div id="form-interactionTag-wrapper" class="card">
    <div class="card-header">
      <strong>Interaction Tags</strong>
    </div>
    <div class="card-body">
      <div class="form-group row">
        <label class="col-12 col-form-label" for="interactionTags">Tags</label>
      </div>
      <div class="form-group row">
        <div class="col-md-9">
          <multiselect v-model="interactionTags" tag-placeholder="Add this as new tag" placeholder="Search or add a tag"
            selectLabel="Click to select" deselectLabel="Click to deselect" label="name" track-by="id"
            :options="interactionTagsAll" :multiple="true" :taggable="isInteractionTagEditor" @tag="addTagToDB"
            :disabled="disableInteractionTags" open-direction="bottom">
          </multiselect>

          <!-- <multiselect v-model="contract.admins" :options="formData.admins" placeholder="Please select an option"
            selectLabel="Select to add" deselectLabel="Select to remove" :multiple="true" :close-on-select="false"
            :clear-on-select="false" :preserve-search="true" label="name" track-by="name">
          </multiselect> -->
        </div>
      </div>
      <!-- Display all tags -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label">Available Tags</label>
      </div>
      <div class="form-group row">
        <p class="col-12">
          <span v-for="tag in interactionTagsAll">
            <div class="btn-group  m-1">
              <span class="btn btn-primary btn-sm cursor-pointer" :class="{ disabled: disableInteractionTags }"
                role="button" @click.stop="toggleTagInInteraction(tag)">
                {{ tag.name }}
              </span>
              <span class="btn btn-sm btn-danger cursor-delete" @click.stop="removeTagFromDb(tag)"><i
                  class="fa-solid fa-trash-can"></i></span>
            </div>
          </span>
        </p>
      </div>
      <div v-if="!locked" class="form-group row">
        <div class="col-12 text-center">
          <button type="button" class="btn btn-success" @click="saveInteractionTags" :disabled="disableInteractionTags">
            Save Tags <i class="fas fa-plus-circle"></i></button>
        </div>
      </div>

    </div>
  </div>
  <!-- </div> -->
</template>
