<script>
export default {
  components: {},
  props: {
    formId: String,
    modelName: String,
    createFlag: Boolean,
    deleteFlag: Boolean,
    previewFlag: Boolean,
    resetFlag: Boolean,
    saveFlag: Boolean,
    submitFlag: Boolean,
    tabIndexStart: {
      type: Number,
      required: false,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    createDisabled: {
      type: Boolean,
      default: false,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    previewDisabled: {
      type: Boolean,
      default: false,
    },
    resetDisabled: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    isSaving: false,
    isSubmitting: false,
    position: String,
    // validation: Object
  },
  data: function () {
    return {
      isLoaded: false,
      isLoading: false,
      failed: false,
      tabIndex: this.tabIndexStart,
      tabIndexCount: 0,
      tabIndexOrder: [
        'submitFlag',
        'previewFlag',
        'saveFlag',
        'createFlag',
        'resetFlag',
        'deleteFlag',
      ],
    };
  },
  computed: {
    flagsList() {
      return {
        'submitFlag': this.submitFlag,
        'previewFlag': this.previewFlag,
        'saveFlag': this.saveFlag,
        'createFlag': this.createFlag,
        'resetFlag': this.resetFlag,
        'deleteFlag': this.deleteFlag,
      }
    },
    tabIndexList() {
      let tabIndexs = {};
      let count = 0;
      for (const flag of this.tabIndexOrder) {
        if (this.tabIndexStart === 0) {
          tabIndexs[flag] = 0;
        } else if (this.flagsList[flag]) {
          tabIndexs[flag] = this.tabIndexStart + count;
          count++;
        }
      } 
      this.$emit("tabIndexCount", this.tabIndexStart + count);
      return tabIndexs;
    },
  },
  methods: {
    vuelidateFocusFirstError(component = this.$parent) {
      const invalidField = _.findKey(
        this.$parent.$v.$params,
        (value, key) => this.$parent.$v[key].$invalid
      );
      // console.log('invalidField', invalidField);

      if (component.status) {
        component.$el.focus();
        return true;
      }

      let focused = false;

      component.$children.some((childComponent) => {
        focused = this.vuelidateFocusFirstError(childComponent);
        return focused;
      });

      return focused;
    },
    createMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formCreate", formId, event);
    },
    deleteMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formDelete", formId, event);
    },
    previewMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formPreview", formId, event);
    },
    resetMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formReset", formId, event);
    },
    saveMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formSave", formId, event);
    },
    submitMethod: function (formId, event) {
      event.preventDefault();
      this.$emit("formSubmit", formId, event);
    },
    dynamicClasses: function() {
      let classes = "";
      if (this.position === 'sticky') {
        classes = classes + " position-sticky bottom-sticky"
      }
      return classes;
    },
    getTabIndex: function(flag) {
      let tabIndexCount = 0;
      
      for (const element of this.tabIndexOrder) {

// console.log(this);
        if (flag === element) {
           break;
        }
      }
    },
  },
};
</script>

<template>
  <div id="am-formSubmit" class="card-footer" :class="dynamicClasses()">
    <button
      v-if="deleteFlag"
      type="delete"
      class="btn btn-danger"
      dusk="btn-delete"
      data-toggle="modal"
      :data-target="'#delete' + modelName + 'Modal'"
      @click="deleteMethod(formId, $event)"
      :tabindex="tabIndexList['deleteFlag']"
      :disabled="deleteDisabled || disabled"
    >
      <!-- :tabindex="tabIndex + 5" -->
      Delete <i class="fas fa-trash-alt"></i>
    </button>
    <button
      v-if="resetFlag"
      type="reset"
      class="btn btn-warning"
      dusk="btn-reset"
      :disabled="resetDisabled || disabled"
      @click="resetMethod(formId, $event)"
      :tabindex="tabIndexList['resetFlag']"
    >
      <!-- :tabindex="tabIndex + 4" -->
      Reset <i class="fas fa-undo-alt"></i>
    </button>
    <button
      v-if="createFlag"
      class="btn btn-secondary"
      dusk="btn-create"
      :disabled="createDisabled  || disabled"
      @click="createMethod(formId, $event)"
      :tabindex="tabIndexList['createFlag']"
    >
      <!-- :tabindex="tabIndex + 3" -->
      Create New <i class="fas fa-plus-circle"></i>
    </button>
    <button
      v-if="saveFlag"
      type="submit"
      class="btn btn-info"
      dusk="btn-save"
      :disabled="$parent.$v.$invalid || disabled"
      @click="saveMethod(formId, $event)"
      :tabindex="tabIndexList['saveFlag']"
    >
      <!-- :tabindex="tabIndex + 2" -->
      Save Draft
      <span
        v-if="isSaving"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <i v-else class="far fa-save"></i>
    </button>
    <button
      v-if="previewFlag"
      type="submit"
      class="btn btn-success"
      dusk="btn-preview"
      :disabled="previewDisabled || $parent.$v.$invalid || disabled"
      @click="previewMethod(formId, $event)"
      :tabindex="tabIndexList['previewFlag']"
    >
      <!-- :tabindex="tabIndex + 1" -->
      Preview <i class="fa-solid fa-eye"></i>
    </button>
    <button
      v-if="submitFlag"
      type="submit"
      class="btn btn-primary float-right"
      dusk="btn-submit"
      :disabled="submitDisabled || $parent.$v.$invalid || disabled"
      @click="submitMethod(formId, $event)"
      :tabindex="tabIndexList['submitFlag']"
    >
      <!-- :tabindex="tabIndex" -->
      Submit
      <span
        v-if="isSubmitting"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <i v-else class="far fa-check-circle"></i>
    </button>
  </div>
</template>
